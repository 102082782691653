// @ts-nocheck
import { graphql } from "gatsby";
import React, { useState } from "react";
import PageLayout from "../components/PageLayout";
import './css/coverage-map.module.scss';
import LoadingSpinner from '../components/LoadingSpinner';

const CoverageMap = () => {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {setLoading(false)}, 6000)

  return (
    <>
      {loading && <div style={{
        zIndex: "2147483640", 
        position: "fixed",
        height: "100vh",
        width: "100vw",
        backgroundColor: "white"}}
      >
        <LoadingSpinner style={{ zIndex: "2147483640" }}/>
      </div>}
      <PageLayout backgroundColor="#f2fafc" containerPadding="0px">
        <title>Coverage Map</title>
        <tmo-web-content-kit kit-type="map" client-id="PLINTRON-GOPHONEBOX" variation="roamdcm-5g"></tmo-web-content-kit>
      </PageLayout>
    </>
  );
};

export default CoverageMap;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
