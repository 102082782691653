import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner: React.FC = () => {
    return (
        <div style={{position: "fixed", left:"50%", top:"50%", transform: "translate(-50%, -50%)"}}>
            <div className="m-auto" style={{width: "30%"}}>
                <img src={"/icons/logo_mini_blue.svg"}></img>
            </div>
            <br/>
            <div className="d-flex align-items-center">
                <Spinner variant="primary" className="m-auto" animation="border" />
            </div>
            <br/>
            <div className="text-center">
                <small>Hang tight.. We're getting everything ready for you.</small>
            </div>
        </div>
    );
}

export default LoadingSpinner;